import classNames from 'classnames';
import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatDateRange } from '@src/shared/src/util/date';
import {
  minutesToHoursAndMinutes,
  pipe,
  map,
  path,
  concat,
  flatten,
  uniq,
} from '@src/shared/src/util/general';
// Constants
import {
  ENVIRONMENT,
  VEHICLE_TYPES,
  SEAT_RES_STATUS,
  TRAIN_OPERATORS,
} from '@src/shared/src/const/app';
// Actions
// Models
import {
  BookingTransportFareModel,
  UserModel,
} from '@src/shared/src/models';
// Interfaces
// Components
import { TransportationBookingSeatInfo } from './TransportationBookingSeatInfo';
import { Tooltip } from '@toolkit/ui';
// Styles

type Props = {
  fare: BookingTransportFareModel;
  bookingItemSeatReservationInfo: string;
  env: ENVIRONMENT;
  profile: UserModel;
};

const getTransportTitle = (fare: BookingTransportFareModel, translateFunc: any) =>
  pipe(
    map(
      pipe(
        path(['segment', 'vehicles']),
        map(concat('BookingInfo.booking.subTitle.')),
        map(translateFunc),
      ),
    ),
    flatten,
    uniq,
  )([fare]);

const getDbahnChangeSeatReservationMarkup = (fare: BookingTransportFareModel, profile: UserModel, env: ENVIRONMENT) => {
  if (
    env === ENVIRONMENT.CONFIRMATION &&
    fare.segment.operators.includes(TRAIN_OPERATORS.DEUTSCHEBAHN) &&
    (fare.seatReservation === SEAT_RES_STATUS.INCLUDED ||
      fare.seatReservation === SEAT_RES_STATUS.AVAILABLE)
  ) {
    return (
      <div>
        <div className="lp-booking-info-item-sub_title">
          <b>{t('TransportationBookingInfo.label.change_seat_res')}</b>
        </div>
        <div>
          <a
            className="lp-booking-info-item-link"
            target="_blank"
            href={t('TransportationBookingInfo.link.change_seat_res_info', {
              email: profile.email
            })}>
            <i className="icon-external_link"></i>
            {t('TransportationBookingInfo.link.change_seat_res')}
          </a>
        </div>
      </div>
    );
  }
};

const supportDisabledOnDemoMessage = (user: UserModel) => {
  // TODO use locize to avoid this mess :)
  var str;
  if(user.preference.language === 'de-DE') {
    str = "In der Demo ist der Zugriff auf den Kundensupport & Help Center deaktiviert.";
  }
  else {
    str = "Our customer support & help center is deactivated in our demo environment.";
  }
  return str;
};

const getPlaneAdditionalServicesMarkup = (fare: BookingTransportFareModel, profile: UserModel, env: ENVIRONMENT) => {
  if (
    env === ENVIRONMENT.CONFIRMATION &&
    fare.segment.vehicles.includes(VEHICLE_TYPES.PLANE)
  ) {
    return (
      <div>
        <div className="lp-booking-info-item-sub_title">
          <b>{t('TransportationBookingInfo.title.services')}</b>
        </div>
        <div>
          <a
            className={classNames(
              "lp-booking-info-item-link",
              {"lp-booking-info-item-link-disabled": profile.organization.isDemo()}
            )}
            target="_blank"
            href={profile.organization.isDemo() ? '' : t('TransportationBookingInfo.link.services', { email: profile.email })}>
            <i className="icon-external_link"></i>
            {t('TransportationBookingInfo.label.services')}
          </a>
          {profile.organization.isDemo() && (
            <Tooltip
              icon="lp-icon-terms lp-icon-terms-more-default"
              bodyTextFirst={supportDisabledOnDemoMessage(profile)}
            />
          )}
        </div>
      </div>
    );
  }
};

export const TransportationBookingInfo: React.FC<Props> = (props) => {
  const { bookingItemSeatReservationInfo, fare, env, profile } = props;

  return (
    <div className="lp-booking-info-item" key={`fare-transport-${fare.id}`}>
      <div className="lp-booking-info-item-inner">
        <div className="lp-booking-info-item-title">{getTransportTitle(fare, t)}</div>
        <div className="lp-booking-info-item-wrapper">
          <div className="lp-booking-info-item-details">
            <div>
              <b>{t('BookingItem.label.route')}:</b>
              &nbsp; {`${fare.segment.depName} - ${fare.segment.arrName}`}
            </div>
            <div>
              <b>{t('BookingItem.label.dates')}:</b>
              &nbsp; {formatDateRange(fare.segment.depAt, fare.segment.arrAt)}
            </div>
            <div>
              <b>{t('BookingItem.label.duration')}:</b>
              &nbsp; {minutesToHoursAndMinutes(fare.segment.duration)}
            </div>
          </div>
          <div className="lp-booking-info-item-details">
            <TransportationBookingSeatInfo
              fare={fare}
              bookingItemSeatReservationInfo={bookingItemSeatReservationInfo}
            />
            {getDbahnChangeSeatReservationMarkup(fare, profile, env)}
            {getPlaneAdditionalServicesMarkup(fare, profile, env)}
          </div>
        </div>
      </div>
    </div>
  );
};
